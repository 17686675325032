import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { useSearchParams, useRouter } from 'src/routes/hooks';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Link } from '@mui/material';

// ----------------------------------------------------------------------

export default function JwtLoginView() {
  const [redirecting, setRedirecting] = useState(false);

  const { login } = useAuthContext();
  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address')
      .test('valid-domain', 'Email must be from a valid domain', (value) => {
        return (
          // Add custom validation logic here if needed
          // For example, checking specific domains
          value.endsWith('.ch') || value.endsWith('.com') // Allow emails ending with .ch
        );
      }),

    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.email, data.password);
      setRedirecting(true);

      setTimeout(() => {
        router.push(returnTo || PATH_AFTER_LOGIN);
      }, 1000);
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg('The login information seems to be incorrect.');
    }
  });

  const renderHead = (
    <Stack spacing={5} sx={{ mb: 2 }}>
      <Typography variant="h4">Sign in</Typography>
    </Stack>
  );

  const loginError = sessionStorage.getItem('loginError');

  const renderForm = (
    <Stack spacing={2}>
      <RHFTextField name="email" label="Email address" />
      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                {password.value ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {!!errorMsg && <b style={{ color: '#C20002' }}>{errorMsg}</b>}
      {loginError && <b style={{ color: '#C20002', textAlign: 'center ' }}>{loginError}</b>}

      <Link
        href="forgot-password"
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-start' }}
      >
        Forgot password?
      </Link>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {redirecting ? 'Redirecting...' : 'Login'}
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
