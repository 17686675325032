/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useRunningSprints } from 'src/api-hooks/useRunningSprints';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  settings: icon('ic_settings'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const [sprintId, setSprintId] = useState(sessionStorage.getItem('sprintId'));
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { checkSprintComplated, sprintComplated } = useRunningSprints();

  useEffect(() => {
    checkSprintComplated(sprintId);

    const interval = setInterval(() => {
      const newSprintId = sessionStorage.getItem('sprintId');
      if (newSprintId !== sprintId) {
        setSprintId(newSprintId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [sprintId]);

  const showTrackAdoption =
    (sprintComplated?.data === 'Sprint complated' &&
      sprintId !== null &&
      sprintId !== undefined &&
      user?.role === 'superAdmin') ||
    user?.role === 'sprintOwner';

  const data = useMemo(() => {
    const items = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      { title: 'Home Page', path: paths.home.root, icon: ICONS.dashboard },
      {
        title: showTrackAdoption ? 'Track Adoption Progress' : '',
        path: showTrackAdoption ? paths.home.sprintResult : '',
        icon: showTrackAdoption ? ICONS.analytics : '',
      },

      { title: 'Settings', path: paths.home.settings, icon: ICONS.settings },
    ].filter((item) => item.title !== ''); // Filter out empty items

    return [{ items }];
  }, [showTrackAdoption]);

  return data;
}
