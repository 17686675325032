/* eslint-disable consistent-return */
import { useState } from 'react';
import axios from 'axios';

export const useSprints = () => {
  const [sprintData, setSprintData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const getSprints = async (company, sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/getAll`,
        { company, sprintId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const getAllSprintBySprintId = async (company, sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/getAllSprintBySprintId`,
        { company, sprintId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const addRunningSprint = async (jsonData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/create`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSprintData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
      setSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const updateRunningSprint = async (jsonData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/update`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSprintData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
      setSprintData(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSprints,
    getAllSprintBySprintId,
    addRunningSprint,
    updateRunningSprint,
    sprintData,
    loading,
    error,
  };
};
