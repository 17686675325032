import { Button, Dialog, Stack, Typography } from '@mui/material';
import React from 'react';

function SecondModal({ createSprintModal, handleCreateSprintModalClose, handleOnClickButton }) {
  return (
    <Dialog
      open={createSprintModal}
      onClose={handleCreateSprintModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '400px', // İstediğiniz genişliği ayarlayabilirsiniz
        },
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center ',
          padding: 4,
        }}
      >
        <Typography variant="h6">Are you sure?</Typography>
        <Typography sx={{ textAlign: 'center', mt: 2 }} variant="body2">
          When a new sprint is started, the existing sprint <b>can only be edited</b> later if there
          is one
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 3, background: 'linear-gradient(to right, #615EFE, #A4A4FA)' }}
          onClick={handleOnClickButton}
        >
          Approve and create a new sprint
        </Button>
      </Stack>
    </Dialog>
  );
}

export default SecondModal;
