import React from 'react';
import useOnlineStatus from './hooks/use-online-status';

const ConnectionStatus = () => {
  const isOnline = useOnlineStatus();
  if (!isOnline) {
    alert('Check your internet connection');
  }

  return <div />;
};

export default ConnectionStatus;
