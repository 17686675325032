/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useSprints } from 'src/api-hooks/useSprints';

import { useAdoptionSprints } from 'src/api-hooks/useAdoptionSprints';
import { useLocation, useNavigate } from 'react-router';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useRunningSprints } from 'src/api-hooks/useRunningSprints';

function AccessPreviousSprint({ handlePrevModalClose }) {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const { checkSprintComplated } = useRunningSprints();

  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [allSprints, setAllSprints] = useState([]);

  const { getSprints, sprintData } = useSprints();
  const { findSprintBySprintId } = useAdoptionSprints();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const sprintId = sessionStorage.getItem('sprintId');

  useEffect(() => {
    getSprints(user?.company, sprintId);
    checkSprintComplated(sprintId);
  }, [sprintId]);

  useEffect(() => {
    setAllSprints(sprintData);
  }, [sprintData]);

  const handleEditButtonClicked = (id) => {
    findSprintBySprintId(user?.company, id).then((res) => {
      const allData = res.data;

      if (res.status === 'Success') {
        navigate('/home/edit-adoption-sprint', { state: { allData } });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 250, mt: 3 }} aria-label="simple table">
        {allSprints !== null && (
          <TableHead>
            <TableRow>
              <TableCell>Sprint Name</TableCell>
              <TableCell align="left">Sprint Date</TableCell>
              <TableCell align="left">Sprint Status</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {allSprints === null ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFEBD4',
                    mb: 2,
                  }}
                >
                  <DangerousIcon color="error" />
                  <Typography variant="subtitle2" sx={{ m: 2, color: 'red' }}>
                    No sprints created yet
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            allSprints
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.sprintName}
                  </TableCell>
                  <TableCell align="left">{row.sprintDate}</TableCell>
                  <TableCell align="left" sx={{ color: row.complated === true ? 'green' : 'red' }}>
                    {row.complated === true ? 'Completed' : 'Not Completed'}
                  </TableCell>
                  <TableCell align="left" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                      disabled={location.pathname === '/home/sprint-results' && !row.complated}
                      endIcon={<Iconify icon="mdi:eye" />}
                      onClick={() => {
                        sessionStorage.setItem('sprintId', row.sprintId);
                        sessionStorage.setItem('sprintName', row.sprintName);
                        handlePrevModalClose();
                        // router.replace('/');
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ color: '#637381' }}>
                        Select
                      </Typography>
                    </Button>
                    {user?.role === 'superAdmin' && (
                      <Button
                        endIcon={<Iconify icon="solar:pen-bold" />}
                        onClick={() => handleEditButtonClicked(row.sprintId)}
                        disabled={row.complated === true}
                      >
                        <Typography variant="subtitle2" sx={{ color: '#637381' }}>
                          Edit
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>

      {allSprints?.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={allSprints.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Sprints per page:"
        />
      )}
    </TableContainer>
  );
}

export default AccessPreviousSprint;
