/* eslint-disable consistent-return */
import { useState } from 'react';
import axios from 'axios';

export const useAdoptionSprints = () => {
  const [adoptionSprintData, setAdoptionSprintData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const createAdoptionSprint = async (
    dimensions,
    company,
    sprintName,
    departments,
    sprintDate,
    optionType,
    selectedManager
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/createSprint`,
        {
          dimensions,
          company,
          sprintName,
          departments,
          sprintDate,
          optionType,
          selectedManager,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const updateAdoptionSprint = async (
    sprintId,
    dimensions,
    company,
    sprintName,
    departments,
    sprintDate,
    optionType,
    assignedManager
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/updateSprint`,
        {
          sprintId,
          dimensions,
          company,
          sprintName,
          departments,
          sprintDate,
          optionType,
          assignedManager,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };

  const getAdoptionSprint = async (company) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/allSprints`,
        { company },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const findSprintBySprintId = async (company, sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/findSprintBySprintId`,
        { company, sprintId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const inviteSprint = async (email) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/sendInvite`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const inviteSprintDimensionOwner = async (email) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/sprints/sendInvite`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAdoptionSprintData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setAdoptionSprintData(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    createAdoptionSprint,
    findSprintBySprintId,
    getAdoptionSprint,
    updateAdoptionSprint,
    inviteSprintDimensionOwner,
    inviteSprint,
    adoptionSprintData,
    loading,
    error,
  };
};
