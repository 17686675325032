/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, Stack, Button, Link, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRouter } from 'src/routes/hooks';
import { useSprints } from 'src/api-hooks/useSprints';
import { useResponsive } from 'src/hooks/use-responsive';
import { useCompany } from 'src/api-hooks/useCompany';
import { AccountPopover } from 'src/layouts/_common';
import FirstModal from '../prev-sprints-modals/FirstModal';
import SecondModal from '../prev-sprints-modals/SecondModal';

function CustomHeader({ isOpen, sprintId, onOpenNav }) {
  const router = useRouter();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { getSprints, sprintData } = useSprints();
  const { getAllCompanies, companyData } = useCompany();

  const [prevSprintsModal, setPrevSprintsModal] = useState(false);
  const [createSprintModal, setCreateSprintModal] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState(sprintId || null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllCompanies(user?.company).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getSprints(user?.company).then((res) => {
      if (res && res.length > 0) {
        const lastElement = res[res.length - 1];
        sessionStorage.setItem('sprintId', lastElement.sprintId);
        sessionStorage.setItem('sprintName', lastElement.sprintName);
        if (!selectedSprint) {
          setSelectedSprint(lastElement);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (sprintData && sprintId) {
      const selected = sprintData?.find((i) => i.sprintId === sprintId);
      setSelectedSprint(selected);
      sessionStorage.setItem('sprintId', sprintId);
    }
  }, [sprintId, sprintData]);

  useEffect(() => {
    if (isOpen) {
      openModal();
    } else {
      handleClickPrevModalClose();
    }
  }, [isOpen]);

  const openModal = () => {
    setPrevSprintsModal(true);
  };

  const handleClickPrevModalClose = () => {
    setPrevSprintsModal(false);
  };

  const handleClickCreateModalClose = () => {
    setCreateSprintModal(false);
  };

  const handleClickCreateButton = () => {
    setPrevSprintsModal(false);
    setCreateSprintModal(true);
  };

  const handleClickNewSprintButton = () => {
    setCreateSprintModal(false);
    router.replace('/home/create-adoption-sprint');
  };

  const lgUp = useResponsive('up', 'lg');

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5, alignItems: 'center' }}>
      {prevSprintsModal && (
        <FirstModal
          prevSprintsModal={prevSprintsModal}
          sprintId={sprintId}
          user={user}
          handleClickButton={handleClickCreateButton}
          handlePrevModalClose={handleClickPrevModalClose}
        />
      )}

      {createSprintModal && (
        <SecondModal
          createSprintModal={createSprintModal}
          handleOnClickButton={handleClickNewSprintButton}
          handleCreateSprintModalClose={handleClickCreateModalClose}
        />
      )}

      {user?.role !== 'user' ? (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
            marginRight={5}
          >
            {!lgUp &&
              (loading ? (
                <Skeleton variant="rectangular" width={150} height={50} />
              ) : (
                <Link href="/">
                  <img src={companyData?.data.companyImage} alt="company-logo" width={150} />
                </Link>
              ))}
            <Box
              sx={{
                width: 260,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!selectedSprint ? (
                <Button
                  variant="outlined"
                  fullWidth
                  endIcon={<ArrowDropDownIcon />}
                  onClick={openModal}
                  sx={{ fontWeight: '400' }}
                >
                  Please select a sprint
                </Button>
              ) : sprintData && selectedSprint ? (
                <Button
                  variant="outlined"
                  fullWidth
                  endIcon={<ArrowDropDownIcon />}
                  onClick={openModal}
                  sx={{ fontWeight: '400' }}
                >
                  {selectedSprint.sprintName} - {selectedSprint.sprintDate}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  endIcon={<ArrowDropDownIcon />}
                  onClick={openModal}
                  disabled
                  sx={{ fontWeight: '400' }}
                >
                  No sprints created yet
                </Button>
              )}
            </Box>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '40%',
              justifyContent: 'flex-end',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1 }}
            >
              <AccountPopover />
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
            marginRight={5}
          >
            <></>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '40%',
              justifyContent: 'flex-end',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1 }}
            >
              <AccountPopover />
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
}

export default CustomHeader;
