/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTrackAdoption } from 'src/api-hooks/useTrackAdoption';
import AccessPreviousSprint from 'src/sections/accessPreviousSprints/table';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { useRunningSprints } from 'src/api-hooks/useRunningSprints';
import { useSprints } from 'src/api-hooks/useSprints';
import { useRouter } from 'src/routes/hooks';

function FirstModal({ prevSprintsModal, user, handlePrevModalClose, handleClickButton }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const { getTenKeyDimensions } = useTrackAdoption();
  const { getRunningSprints, checkSprintComplated } = useRunningSprints();
  const { getSprints, sprintData } = useSprints();

  const sprintId = sessionStorage.getItem('sprintId');
  const incompleteSprints = sprintData?.filter((sprint) => !sprint.complated);
  const router = useRouter();

  useEffect(() => {
    getTenKeyDimensions(sprintId, user?.company);
    getRunningSprints(sprintId, user?.company);
    getSprints(user?.company);
    checkSprintComplated(sprintId);
  }, [sprintId]);

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '600px', // Set your width here
            },
          },
        }}
      >
        <DialogTitle>Adoption Sprints</DialogTitle>
        <DialogContent>
          To start a new sprint, <b>you must complete</b> all questions on all dimensions.
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant="contained" startIcon={<BackIcon />} onClick={handleClose}>
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={prevSprintsModal}
        onClose={handlePrevModalClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px', // Set your width here
            },
          },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center ',
            padding: 4,
          }}
        >
          <Typography variant="h6">Adoption Sprints</Typography>
          <Typography variant="body2">
            Show detailed results of previous adoption sprints:
          </Typography>
          <AccessPreviousSprint handlePrevModalClose={handlePrevModalClose} />

          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (incompleteSprints === undefined) {
                router.replace('/home/create-adoption-sprint');
              }
              if (incompleteSprints?.length === 0) {
                handleClickButton();
              } else {
                setAlertOpen(true);
              }
            }}
          >
            Create New Sprint
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}

export default FirstModal;
